import * as React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import "../styles/portfolio.css";

const Makenewpage = ({ dataNode, selectedValue }) => {
  // console.log('make page for: ' + selectedValue)
  // console.log(dataNode)

  if (selectedValue !== "All") {
    /*if (selectedValue === "Architecture") {
        selectedValue = null;
    } */
    dataNode = dataNode.filter((item) => item.tags[0] === selectedValue);
  }
  // console.log(selectedValue)

  return dataNode.map((item, i) => (
    <Link key={item.uid + i} to={item.uid} className="project">
      <h3 className="text">{item.data.project_name.text}</h3>

      <GatsbyImage
        image={item.data.header_image.gatsbyImageData}
        alt={
          item.data.header_image.alt
            ? item.data.header_image.alt
            : "Portfolio Image"
        }
        className="image"
      />

      <div className="overlay"></div>
    </Link>
  ));
};

export default Makenewpage;

/* Page only checks against the first tag and not the rest.  */
