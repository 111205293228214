import * as React from "react";
import { useState } from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import Spacer from "../../components/Spacer";
import Makenewpage from "../../components/makenewpage";
import "../../styles/portfolio.css";

const PortfolioPage = ({ data }) => {
  const projects = data.allPrismicPortfoliopage.nodes;
  //console.log(projects);
  // const url1 = data.allPrismicPortfoliopage
  // console.log(` URL1 = ${url1} `)
  // console.log(url1)
  // console.log("  prooject 2" + projects[2])
  const [selectedOption, setSelectedOption] = useState("All");

  let tagsarr = [];
  projects.map((item) => item.tags.map((item2) => tagsarr.push(item2)));
  tagsarr = removeDuplicates(tagsarr);
  // console.log("tagarrr  :" + tagsarr)

  function removeDuplicates(arr) {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }

  return (
    <Layout pageTitle="Portfolio">
      <div className="mainpage">
        <div className="landinggrid homegrid portfoliogrid">
          <div className="landingwhitespace"></div>
          <div className="landingwords text valuewords">
            <p>Transparency builds trust, efficiency sustains it.</p>
          </div>

          <StaticImage
            alt="Portfolio Landing"
            src="../../images/portfolio.png"
            className="image landingimage"
          />
        </div>

        <Spacer classes="h50" />

        <div className="portfolioheader text">
          <p className="h1">Portfolio</p>
          <select
            className="select"
            onChange={(e) => setSelectedOption(e.target.value)}
            value={selectedOption}
          >
            <option value="All">All</option>
            {
              /*
                            <option value="Architecture" >Architecture</option>
                      <option value="Interior Design">Interior Design</option>}*/

              tagsarr.map((item, i) => (
                <option key={item + i} value={item}>
                  {" "}
                  {item}{" "}
                </option>
              ))
            }
          </select>
        </div>

        <Spacer classes="h30" />
        <div className="portfoliogrid3">
          <Makenewpage
            dataNode={projects}
            pageTitle="portfolio"
            selectedValue={selectedOption}
          />
        </div>
        <Spacer classes="h30" />
      </div>

      <Spacer classes="h50"></Spacer>
    </Layout>
  );
};

export const query = graphql`
  query {
    allPrismicPortfoliopage {
      nodes {
        uid
        data {
          project_name {
            text
            html
          }
          body {
            ... on PrismicPortfoliopageDataBodyImages {
              id
              slice_label
              slice_type
            }
          }
          header_image {
            gatsbyImageData
            alt
          }
        }
        type
        url
        tags
      }
    }
  }
`;

export const Head = () => <Seo title="Portfolio" />;

export default PortfolioPage;
